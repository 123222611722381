export const isMobileOrTouch = () => {
    // Methods to determine device type...
    // Detecting if cellular connection using navigator
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    const cellConnection = connection && connection.type === 'cellular' ? true : false;
    // Detecting if user agent contains word mobile 
    const mobileUser = navigator.userAgent.match(/Mobi/) ? true : false;
    // Detecting screen size using window and/or document
    const screenWidth = () => Math.max(window.innerWidth, document.documentElement.clientWidth || 0);
    const smallScreen = screenWidth() <= 812 ? true : false;
    const ipadSize = screenWidth() === 1366 || screenWidth() === 1112 || screenWidth() === 1024 || screenWidth() === 834 || screenWidth() === 768 ? true : false;
    // Detecting if orientation is defined. NOTE: deprecated 2018
    const orientationSet = typeof window.orientation !== "undefined" ? true : false;
    // Detecting if device supports touch events. NOTE: may return false positives with laptop/desktop touchpad/trackpads
    const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints ? true : false;
    // console.log(`Cell Connection: ${cellConnection}\nMobile User: ${mobileUser}\nSmall Screen: ${smallScreen}\niPad Size: ${ipadSize}\nOrientation Set: ${orientationSet}\nSupports Touch:  ${supportsTouch}`)
    return cellConnection || mobileUser || smallScreen || ipadSize || orientationSet || supportsTouch ? true : false;
  }

  export const isSmallScreen = () => {
    // Detecting screen size using window and/or document
    const screenWidth = () => Math.max(window.innerWidth, document.documentElement.clientWidth || 0);
    const smallScreen = screenWidth() <= 420 ? true : false;
    // console.log('isSmallScreen: ', smallScreen)
    return smallScreen ? true : false;
  }