import styled from 'styled-components';

export const HomeWrap = styled.section`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto;
    grid-template-rows: auto;
    justify-content: center;
    // max-height: 100vh;
    // max-width: 100vw;
`;
export const HomeImg = styled.img`
    height: auto;
    // width: 100%;
    padding: 0;
    margin: 0;
    max-height: 1200px;
    max-width: 1200px;
    @media only screen and (max-height: 1200px)  {
        max-height: 100vh;
     }
    @media only screen and (max-width: 1200px)  {
       max-width: 100vw;
    }
`;