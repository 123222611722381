import React from 'react';
// Styled components
import styled from 'styled-components';
import { Instagram } from 'styled-icons/fa-brands/Instagram'

const UnderConstructionWrap = styled.section`
    z-index: 9999;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(232,232,232);
    background-size: cover;
    font-size: 1rem;
`;
const UnderConstructionText = styled.header`
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    // color: rgba(30,40,30,1);
    h1 {
      margin: 1rem
    } 
`;
const InstaIcon = styled(Instagram)`
    height: 3rem;
    width: 3rem;
    vertical-align: middle;
    margin: .5rem 0;
    cursor: pointer;
    &:hover {
      background: -webkit-gradient(linear,left bottom,right top,from(rgb(255,215,0)),to(rgb(208,0,180)));
      color: white;
    }
`;


class UnderConstruction extends React.Component {

  render() {
    return (

      <UnderConstructionWrap>
        <UnderConstructionText>
          <h2>Coming Soon!</h2>
          <h1>Adrienne Fox Photography</h1>
          <h3>Visit me on Instagram</h3>
          <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/adrienneleighfox"><InstaIcon/></a>
        </UnderConstructionText>
      </UnderConstructionWrap>

    );
  }
}

export default UnderConstruction




