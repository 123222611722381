import styled from 'styled-components';

export const HustleWrap = styled.section`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto;
    justify-content: center;
    padding: 6vh 6vw 0;
`;
export const HustleTitle = styled.h1`
    font-family: bebas-kai,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.5rem;
    text-align: center;
    margin: 2.5rem 0 .5rem 0;
`;
export const HustleText = styled.p`
    font-szie: .8rem;
    text-align: justify;
    max-width: 800px;
    line-height: 1.4;
`;
export const GridHeaderWrap = styled.section`
    display: grid;
    grid-gap: 0;
    grid-template-columns: auto;
    justify-content: left;
    padding: 0 6vw 0;
    margin: 10vh 0 0 0;
    line-height: 1.4;
`;
export const HustlePhotoGrid = styled.section`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto auto auto;
    justify-content: center;
    padding: 3vh 6vw 0;
`;
export const ImageResponsive = styled.img`
    display: block;
    height: auto;
    width: 100%;
    padding: 0;
    margin: 0;
    max-height: 600px;
    max-width: 600px;
    object-fit: contain;
`;