import { ProjectData } from './ProjectData.js';

// Function converting imported array into array of image objects each with
// key, id, name, source, project, project order, and project count properties
const importAllImages = (IMPORT) => {
  let imageArray = [];
  let imageId;
  let projectOrder;
  let projectIndex;
  let projectLayout;

  // importing project data and creating index variable for each project
  ProjectData.map(i => {
    // console.log('ProjectData: ', ProjectData, " : ")

    return ProjectData[i.id + "Index"] = 0;
  });

  IMPORT.keys().map((item, index) => {
    let imageSource = IMPORT(item);
    let imageKey = `img${index + 1000}`;
    let fileName = item.replace(`./`, '');
    // NOTE assumes image file follows naming convention:
    // projectName_imageName_imageName.png/svg/jpg/jpeg
    // characters before first underscore are projectName
    // characters between first underscore and first period are imageName
    let projectName = item.split('./').pop().split('_').shift();
    let imageName = item.replace(`./${projectName}_`, '').split('.')[0];
    let prettyPrint = (str) => {
      let frags = str.split('_');
      frags.forEach((frag, index) => {
        frags[index] = frags[index].charAt(0).toUpperCase() + frags[index].slice(1);
      });
      return frags.join(' ');
    }
    let imageTitle = prettyPrint(imageName)
    // creating consecutive ids based on project number
    // ex. project 'a' images: 101, 102 etc.; project 'b': 202, 203 etc.
    ProjectData.map(i => {
      if (projectName === i.id) {
        imageId = i.index + ProjectData[i.id + "Index"];
        projectOrder = ProjectData[i.id + "Index"];
        projectIndex = i.index;
        projectLayout = i.layout;
        return ProjectData[i.id + "Index"]++;
      } else {
        return null
      }
    });

    let imageObject = {
      key: imageKey,
      id: imageId,
      file: fileName,
      title: imageTitle,
      src: imageSource,
      project: projectName,
      projectOrder: parseInt(projectOrder, 10),
      projectCount: null,
      projectIndex: projectIndex,
      projectLayout: projectLayout
    };
    return imageArray.push(imageObject);
  });

  // counting number of occurences of specific value in array of objects
  // and adding project count key/value to image objects
  const howMany = (arrayOfObj, property) => {
    const counts = arrayOfObj.reduce((acc, obj) => {
      const value = obj[property];
      return acc.set(value, acc.has(value) ? acc.get(value) + 1 : 1);
    }, new Map());
    return search => counts.get(search) || 0;
  };
  ProjectData.map(i => {
    ProjectData[i.id + "Count"] = howMany(imageArray, 'project')(i.id)
    let projectCount = ProjectData[i.id + "Count"]
    return imageArray.map(img => {
      if (i.id === img.project) {
        return img.projectCount = projectCount;
      } else {
        return null
      }
    });
  });
  return imageArray;
};

// Importing image files from folder 'images' using Webpack function require.context
// https://webpack.js.org/guides/dependency-management/

let ImageImport = importAllImages(require.context('images', false, /\.(png|jpe?g|svg)$/));
let ImageImportSmall = importAllImages(require.context('images_small', false, /\.(png|jpe?g|svg)$/));

// console.log('Image Import: ', ImageImport, ' Image Import Small: ', ImageImportSmall)

export {
ImageImport, ImageImportSmall
}
