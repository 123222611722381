import React from 'react';
// NOTE instafeed.js relies on instagram API that will die in 2020
import Instafeed from 'instafeed.js';
// Styled Components
import { InstaGridWrap } from './style.js';

export const Insta = () => {

  let feed = new Instafeed({
    target: "instaImages",
    get: 'user',
    userId: process.env.REACT_APP_IG_USER_ID,
    clientID: process.env.REACT_APP_IG_CLIENT_ID,
    accessToken: process.env.REACT_APP_IG_ACCESS_TOKEN,
    resolution: "standard_resolution",
    limit: 33,
    template: '<a href="{{link}}" target="_blank"><img style="display: block; width: 32vw; height: 32vw; max-height:200px; max-width:200px; object-fit: cover;" src="{{image}}"/></a>'
  });
  feed.run();
  return (
    <InstaGridWrap id="instaImages" ></InstaGridWrap>
  )
}