import styled from 'styled-components';

export const PubWrap = styled.section`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto;
    justify-content: center;
    padding: 6vh 6vw 0;
`;

export const PubHead = styled.h1`
    font-size: 2.5rem;
    text-align: center;
    font-weight: 400;
    font-style: normal;
    font-family: bebas-kai,sans-serif;
    margin: 2.5rem 0 .5rem 0;
`;
export const PubText = styled.p`
    font-szie: .8rem;
    text-align: justify;
    max-width: 800px;
    margin-top: 3rem;
`;
export const ResponsiveImage = styled.img`
    width: 100%;
    height: auto;
    // max-height: 800px;
    max-width: 800px;
    margin: 0 auto;
    left: 0;
    right: 0;
    @media (min-width: 480px) {
        height: auto;
    } 
`;