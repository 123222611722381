import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  *, *:link, *:visited, *:focus, *:hover, *:active, button, ul, li, p {
    margin: 0;
    padding: 0;
    text-decoration:none;
    color:inherit;
  }

  #root, html, body {
    width: 100%;
    height: 100%;
  }

  body {
    color: rgba(0,0,0,1);
    font-family: 'Merriweather Sans', sans-serif;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-family: 'Quattrocento Sans', sans-serif;
    font-family: bebas-kai, sans-serif;
    font-family: source-sans-pro, sans-serif;
  }
  .bebasKai {
    font-family: bebas-kai, sans-serif;
  }
  .color1 {
    color: rgb(89,66,66); 
  }
  .color2 {
    color: rgb(121,77,83);
  }
  .color3 {
    color: rgb(183,107,121);
  }
  
  .fullGrid {
    display: grid;
    grid-gap: 0;
    height: 100%;
    grid-template-columns:1fr;
    grid-template-areas:
              ".headerContent"
              ".mainContent"
              ".footerContent";
    grid-template-rows: auto 1fr auto;
  }
  .headerContent {
    grid-area: headerContent;
  }
  .mainContent {
    grid-area: mainContent;
  }
  .footerContent {
    grid-area: footerContent;
  }



`

export default GlobalStyle;