import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// Styled components
import { NavHeader, ArtDropdown, AFLogo, MenuIcon, ArrowDropDownIcon, ProjLi, ProjList, LogoHome, NavLi, NavList } from './style.js';
// Components
import { ProjectData } from '../tools/ProjectData.js';
// import { ImageImport } from '../tools/ImageImport.js';
import afLogo from '../../logos/AF-Logo1.png'

class NavBar extends React.Component {

    clientWidth = () => {
        return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    }

    state = {
        navListOpen: this.clientWidth() > 480,
        projListOpen: false,
        bioListOpen: false,
        isMobileView: this.clientWidth() < 480,
    }

    navListToggle = (e) => {
        this.setState({ ...this.state, navListOpen: !this.state.navListOpen, projListOpen: false });
    };
    projListToggle = (e) => {
        this.setState({ ...this.state, projListOpen: !this.state.projListOpen });
    };
    bioListToggle = (e) => {
        this.setState({ ...this.state, bioListOpen: !this.state.bioListOpen });
    };

    render() {

        return (
            <div>
                {this.state.isMobileView === true ?
                    (
                        <NavHeader>
                            <Link to={`/`}><LogoHome><AFLogo src={afLogo} />ADRIENNE FOX</LogoHome></Link>
                            <MenuIcon onClick={this.navListToggle} />
                            {this.state.navListOpen === true &&
                                <NavList>
                                    <NavLi style={{borderBottom:"none"}}>
                                        <ArtDropdown onClick={this.projListToggle}>ART<ArrowDropDownIcon /></ArtDropdown>
                                        {this.state.projListOpen === true &&
                                            <ProjList>
                                                {ProjectData.map(i => {
                                                    return (<Link to={`/projects/${i.url}`} key={i.id}><ProjLi onClick={this.navListToggle}>{i.title}</ProjLi></Link>)
                                                })}
                                            </ProjList>
                                        }
                                    </NavLi>   
                                    <Link style={{cursor:"pointer"}} to={`/hustle`}><NavLi> HUSTLE</NavLi></Link>
                                    <NavLi onClick={this.bioListToggle}>
                                        <span>BIO</span>
                                        {this.state.bioListOpen === true &&
                                            <ProjList>
                                                <Link style={{cursor:"pointer"}} to={`/bio`} key="bio"><ProjLi onClick={this.bioListToggle}>Biography</ProjLi></Link>
                                                <Link style={{cursor:"pointer"}} to={`/publications`} key="pub"><ProjLi onClick={this.bioListToggle}>Publications</ProjLi></Link>
                                                <Link style={{cursor:"pointer"}} to={`/contact`} key="contact"><ProjLi onClick={this.bioListToggle}>Contact</ProjLi></Link>
                                            </ProjList>
                                        }
                                    </NavLi>
                                </NavList>
                            }
                            <Router><Route /></Router>
                        </NavHeader>

                    )
                    : (
                        <NavHeader>
                            <Link to={`/`}><LogoHome><AFLogo src={afLogo} />ADRIENNE FOX</LogoHome></Link>
                            <MenuIcon onClick={this.navListToggle} />
                            {this.state.navListOpen === true &&
                                <NavList>
                                    <NavLi onMouseEnter={this.projListToggle} onClick={this.projListToggle} onMouseLeave={this.projListToggle}>
                                        <span>ART</span>
                                        {this.state.projListOpen === true &&
                                            <ProjList>
                                                {ProjectData.map(i => {
                                                    return (<Link to={`/projects/${i.url}`} key={i.id}><ProjLi onClick={this.projListToggle}>{i.title}</ProjLi></Link>)
                                                })}
                                            </ProjList>
                                        }
                                    </NavLi>
                                    <Link to={`/hustle`}><NavLi> HUSTLE</NavLi></Link>
                                    {/* <Link to={`/insta`}><NavLi> INSTA</NavLi></Link> */}
                                    <NavLi onMouseEnter={this.bioListToggle} onClick={this.bioListToggle} onMouseLeave={this.bioListToggle}>
                                        <span>BIO</span>
                                        {this.state.bioListOpen === true &&
                                            <ProjList>
                                                <Link to={`/bio`} key="bio"><ProjLi onClick={this.bioListToggle}>Biography</ProjLi></Link>
                                                <Link to={`/publications`} key="pub"><ProjLi onClick={this.bioListToggle}>Publications</ProjLi></Link>
                                                <Link to={`/contact`} key="contact"><ProjLi onClick={this.bioListToggle}>Contact</ProjLi></Link>
                                            </ProjList>
                                        }
                                    </NavLi>
                                </NavList>
                            }
                            <Router><Route /></Router>
                        </NavHeader>
                    )

                }
            </div>
        );
    }
}

export default NavBar;
