import React from 'react';
// Styled components
import { PubWrap, PubHead, PubText, ResponsiveImage } from './style.js';
// Image Imports
import volcanoChoir from './assets/volcanoChoir.jpg'
import volcanoChoir2 from './assets/volcanoChoir2.jpg'
import volcanoChoir3 from './assets/volcanoChoir3.jpg'
import sallieFord from './assets/sallieFord.jpg'
import rollingStone from './assets/rollingStoneItaly.jpg'
import fader from './assets/fader.jpg'
import inchesSin from './assets/12inchesSin.jpg'


class Publications extends React.Component {
  
  render() {
    // console.log('Publication Props:', this.props)
    return (

      <PubWrap>
        <PubHead>PUBLICATIONS</PubHead>
        <PubText><span className="bebasKai">ALBUM COVER</span>&ensp;Volcano Choir</PubText>
        <ResponsiveImage src={volcanoChoir} />
        <ResponsiveImage src={volcanoChoir2} />
        <ResponsiveImage src={volcanoChoir3} />
        <PubText><span className="bebasKai">ALBUM COVER</span>&ensp;Sally Ford</PubText>
        <ResponsiveImage src={sallieFord} />
        <PubText><span className="bebasKai">MAGAZINE</span>&ensp;Rolling Stone Italia</PubText>
        <ResponsiveImage src={rollingStone} />
        <PubText><span className="bebasKai">MAGAZINE</span>&ensp;Fader</PubText>
        <ResponsiveImage src={fader} />
        <PubText><span className="bebasKai">BOOK</span>&ensp;VOLUME IV: 12" OF SIN</PubText>
        <ResponsiveImage src={inchesSin} />
      </PubWrap>

    );
  }
}

export default Publications