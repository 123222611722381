// title is displayed in the navbar dropdown menu and art view
// id is used to match image files with projects - begin image file name with appropriate project id
// url is text displayed in url for each project

export const ProjectData = [
  {
    title: "Family Portraits",
    id: "fam",
    url: "fam",
    index: 100,
    layout: "column"
  },
  {
    title: "Polaroid 600",
    id: "polaroid",
    url: "polaroid",
    index: 200,
    layout: "row"
  },
  {
    title: "Zoological",
    id: "zoo",
    url: "zoo",
    index: 800,
    layout: "column"
  },
  {
    title: "Topography",
    id: "topo",
    url: "topo",
    index: 700,
    layout: "row"
  },
  {
    title: "Borderline",
    id: "bor",
    url: "bor",
    index: 300,
    layout: "column"
  },
  {
    title: "Sex With Furniture",
    id: "swf",
    url: "swf",
    index: 500,
    layout: "column"
  }
];
