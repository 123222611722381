import styled, { css } from 'styled-components';
import { Menu, ArrowDropDown } from 'styled-icons/material'
import { slideDown } from '../tools/KeyFrames.js';

export const NavHeader = styled.nav`
    background-color: rgba(255,255,255,0);
    padding: 3.5rem 0 0;
    min-height: 3.5rem;
    
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: left;
    @media (min-width: 480px) {
        min-width: 530px;
        width: 90vw; 
        max-width: 1500px
        height: auto;
        padding: 2rem 0 1rem;
        justify-content: space-between;
    }
`;
export const ArtDropdown = styled.nav`
    width: 100vw;
    display: block;
    padding-bottom: 8px;
    margin-bottom: -8px;
    border-bottom: 1px solid rgba(50,50,50,1);
    @media (min-width: 480px) {

    }
`;
export const AFLogo = styled.img`
    height: auto;
    width: 1.5rem;
    margin-right: .4rem;
    position: relative;
    top: 4px;
    @media (min-width: 480px) {
        height: auto;
        width: 2rem;
        top: 10px;
    } 
`;
export const MenuIcon = styled(Menu)`
    height: 2.5rem;
    width: 2.5rem;
    position: absolute;
    right: 2vw;
    top: 2vh;
    @media (min-width: 480px) {
        display: none;
    } 
`;
export const ArrowDropDownIcon = styled(ArrowDropDown)`
    width: 2.5rem;
    float: right;
    margin: -.7rem .5rem 0 0;
    @media (min-width: 480px) {
        display: none;
    } 
`;
export const NavListMixin = css`
    height: auto;
    list-style-type: none;
    padding: .2rem 0 0 0;
    background-color: rgba(255,255,255,1);
    color: rgba(25,25,25,1);
    @media (min-width: 480px) {
        background-color: inherit;
        color: inherit;
        display: grid !important;
        grid-template-columns: auto auto auto auto auto;
        justify-content: right;
    }
    // animation-name:${slideDown}; animation-duration: 2s; animation-timing-function: ease; animation-delay: 0s; animation-iteration-count: 1; animation-direction: normal; animation-fill-mode: forwards; animation-play-state: running;
`;
export const NavList = styled.ul`${NavListMixin}`;

export const NavLiMixin = css`
    height: auto;
    z-index:2;
    font-size: 1rem;
    padding: .5rem 0;
    border-bottom: 1px solid rgba(50,50,50,1);
    width: 100vw;
    // cursor: pointer;
    text-indent: 10px;
    @media (min-width: 480px) {
        border: none;
        font-size: 1rem;
        width: auto
        padding: .2rem 1rem;
        &:hover {
            // background-color: rgba(220,220,220,.5);
            color: rgba(183,107,121,1)
        } 
    }  
    animation-name:${slideDown}; animation-duration: 5s; animation-timing-function: ease; animation-delay: 0s; animation-iteration-count: 1; animation-direction: normal; animation-fill-mode: forwards; animation-play-state: running;
`;
export const NavLi = styled.li`${NavLiMixin}`;

export const LogoHome = styled.div`
    position: absolute;
    left: 2vw;
    top: 2vh;
    font-size: 1.2rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: .2rem .2rem .8rem;
    @media (min-width: 480px) {
        position: unset;
        font-size: 1.2rem;
        &:hover {
            // background-color: rgba(220,220,220,.5);
            color: rgba(183,107,121,1)
        }
    } 
`;
export const ProjListMixin = css` 
    height: auto;
    list-style-type: none;
    padding: .5rem 0;
    @media (min-width: 480px) {
      position: absolute;
      background-color: rgba(255,255,255,1);
      color: rgba(50,50,50,1);
      border: 1px solid rgb(128,128,128);
    }
    //animation-name:${slideDown}; animation-duration: 1s; animation-timing-function: ease; animation-delay: 0s; animation-iteration-count: 1; animation-direction: normal; animation-fill-mode: forwards; animation-play-state: running;
`;

export const ProjList = styled.ul`${ProjListMixin}`;

export const ProjLiMixin = css`
    height: auto;
    border-bottom: 1px solid rgba(150,150,150,1);
    padding: 4px 1rem;
    width: calc(100vw - 2rem);
    font-size: 1rem;
    text-indent: 0;
    white-space: nowrap;
    @media (min-width: 480px) {
        width: auto;
        border: none
        &:hover {
            color: rgba(183,107,121,1)
        }
    }  
    animation-name:${slideDown}; animation-duration: 5s; animation-timing-function: ease; animation-delay: 0s; animation-iteration-count: 1; animation-direction: normal; animation-fill-mode: forwards; animation-play-state: running;
`;

export const ProjLi = styled.li`${ProjLiMixin}`;