import styled from 'styled-components';
import BioLogo from './assets/AF-Logo1.png'

export const BioWrap = styled.section`
    display: grid;
    grid-gap: 0 1rem;
    grid-template-columns: auto;
    justify-content: center;
    padding: 6vh 6vw 0;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    @media (min-width: 880px) {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 2.5rem auto;
        padding: 6vh 6vw 0 0;
    } 
`;
export const BioHead = styled.h1`
    // font-size: 28vw;
    // text-align: center;
    // font-weight: 400;
    // font-style: normal;
    // font-family: bebas-kai,sans-serif;
    // margin: 0;
    // line-height: 1;
    // color: rgb(89,66,66); 
    // background-color: rgb(183,107,121);

    font-size: 2rem;
    text-align: left;
    font-weight: 400;
    font-style: normal;
    font-family: bebas-kai,sans-serif;
    margin: 0;
    text-align: center;
    line-height: 1;
    grid-row: 1;
    grid-column: 1;
    @media (min-width: 880px) {
        grid-column: 2;
        padding: 0 0 0 2rem;
        text-align: left;
    }
`;
export const BioSubHead = styled.h2`
    font-size: 2rem;
    text-align: center;
    font-weight: 400;
    font-style: normal;
    font-family: bebas-kai,sans-serif;
    margin: 2rem 0;
    
`;
export const BioText = styled.p`
    font-size: 1rem;
    text-align: justify;
    max-width: 800px;
    // background-color: rgb(89,66,66); 
    // color: rgb(183,107,121);
    grid-row: 2;
    padding: 1rem 0;
    @media (min-width: 880px) {
        padding: 0 0 0 2rem;
        grid-column: 2;
    }
`;
export const ResponsiveImage = styled.img`
    width: 100%;
    height: auto;
    min-height: 200px;
    min-width: 200px;
    max-height: 1500px;
    max-width: 1500px;
    margin: 0 auto;
    left: 0;
    right: 0;
    @media (min-width: 880px) {
        height: auto;
        grid-row-start: 1;
        grid-row-end: 3;
    } 
`;

export const LogoTile = styled.div`
    background-image: url(${BioLogo});
    background-size: 2vw;
    background-repeat: space;
`;