import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
// Styled Components
import GlobalStyle from 'components/tools/GlobalCSS.js';
// Components
import Nav from 'components/Nav/Nav.js';
import Footer from 'components/Footer/Footer.js';
import Home from 'components/Home/Home.js';
import Bio from 'components/Bio/Bio.js';
import Publications from 'components/Publications/Publications.js';
import Contact from 'components/Contact/Contact.js';
import { Insta } from 'components/Insta/Insta.js';
import Hustle from 'components/Hustle/Hustle.js';
import ModalSwitch from 'components/tools/ModalSwitch.js';
import UnderConstruction from 'components/Home/UnderConstruction.js';
import Art from 'components/Art/Art.js'; // To display view to list projects
import { isMobileOrTouch, isSmallScreen } from 'components/tools/DeviceCheck.js';
import { ProjectData } from 'components/tools/ProjectData.js';
import { ImageImport, ImageImportSmall } from 'components/tools/ImageImport.js';

class App extends React.Component {

  debugLogs = (showLogs) => {
    // ERROR: Line 21:  Expected an assignment or function call and instead saw an expression  no-unused-expressions
    // eslint-disable-next-line
    (!showLogs && typeof (window.console) === 'undefined')
      ? window.console = {}
      : (!showLogs)
        ? window.console.log = function () { }
        : null;
  }

  state = {
    deviceCheck: isMobileOrTouch(),
    projectData: ProjectData,
    imageImport: isSmallScreen() ? ImageImportSmall : ImageImport,
    debugLogs: this.debugLogs(false),
  };

  render() {

    const history = createBrowserHistory();

    return (

      <Router history={history}>
        <div className="fullGrid">
          <GlobalStyle />
          <div className="headerContent"><Nav /></div>
          <div className="mainContent">
          <Switch>
            <Route exact path="/" render={props => (<Home deviceCheck={this.state.deviceCheck} projectData={this.state.projectData} imageImport={this.state.imageImport} debugLogs={this.state.debugLogs} {...props} />)} />
            <Route path="/bio" render={props => (<Bio deviceCheck={this.state.deviceCheck} projectData={this.state.projectData} imageImport={this.state.imageImport} debugLogs={this.state.debugLogs} {...props} />)} />
            <Route path="/publications" render={props => (<Publications deviceCheck={this.state.deviceCheck} projectData={this.state.projectData} imageImport={this.state.imageImport} debugLogs={this.state.debugLogs} {...props} />)} />
            <Route path="/contact" render={props => (<Contact deviceCheck={this.state.deviceCheck} projectData={this.state.projectData} imageImport={this.state.imageImport} debugLogs={this.state.debugLogs} {...props} />)} />
            <Route path="/hustle" render={props => (<Hustle deviceCheck={this.state.deviceCheck} projectData={this.state.projectData} imageImport={this.state.imageImport} debugLogs={this.state.debugLogs} {...props} />)} />
            <Route path="/insta" render={props => (<Insta deviceCheck={this.state.deviceCheck} projectData={this.state.projectData} imageImport={this.state.imageImport} debugLogs={this.state.debugLogs} {...props} />)} />
            <Route path="/art" render={props => (<Art deviceCheck={this.state.deviceCheck} projectData={this.state.projectData} imageImport={this.state.imageImport} debugLogs={this.state.debugLogs} {...props} />)} />
            <Route path="/projects" render={props => (<ModalSwitch deviceCheck={this.state.deviceCheck} projectData={this.state.projectData} imageImport={this.state.imageImport} debugLogs={this.state.debugLogs} {...props} />)} />
            <Route path='/instagram' component={() => window.location = 'https://instagram.com/adrienneleighfox'} />
            <Route component={Bio} />
          </Switch>
          </div>
          <div className="footerContent"><Footer /></div>
        </div>
      </Router>

    )
  }// End render()
}// End App

export default App
