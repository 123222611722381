import React from 'react';
// Styled components
import { BioText, BioWrap, BioHead, ResponsiveImage, LogoTile } from './style.js';
import BioPortrait from './assets/bio_03.jpg'
// import BioLogo from './assets/AF-Logo1.png'

class Bio extends React.Component {
  
  render() {
    // console.log('Bio Props:', this.props)
    return (

      <BioWrap>
        <BioHead>BIO</BioHead>
        {/* <LogoTile /> */}
        <ResponsiveImage src={BioPortrait} />
        <BioText>Adrienne Fox, born in South Dakota, is a Minneapolis-based photographer whose work examines the intersection between imagination, authentic memory, creative impulse and personal experience. Her photographs are at once humorous and nostalgic, juxtaposing private, oddball moments with natural, stripped-down settings. The stories they tell are familiar and disjointed, inviting the viewer to create new narratives and serving as a catalyst for a range of associations and emotions.</BioText>
      </BioWrap>

    );
  }
}

export default Bio