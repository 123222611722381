import {keyframes} from 'styled-components';

export const fadeIn= keyframes`
    from    { filter: opacity(0);}
    to   { filter: opacity(1);}
`
export const fadeOut= keyframes`
    from    { filter: opacity(1);}
    to   { filter: opacity(0);}
`
export const slideForward= keyframes`
    0% { transform: translateZ(0); }
    100% { transform: translateZ(160px); }
`
export const slideDown= keyframes`
    0% { max-height: 0; }
    100% { max-height: 1000px; }
`


// easeOutQuad
// 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940)

export const shadowFadeOut= keyframes`
    0% { box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35); }
    100% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }
`
// easeOutQuad
// cubic-bezier(0.250, 0.460, 0.450, 0.940)

export const scaleUp= keyframes`
    0% {  transform: scale(0.5); }
    100% {  transform: scale(1);  }
`
// easeOutSine
// cubic-bezier(0.390, 0.575, 0.565, 1.000)

export const imageShow= keyframes`
    0% {  
        filter: opacity(0);
        transform: scale(0.5); 
        transform: translateZ(0);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
    100% {  
        filter: opacity(1);
        transform: scale(1);  
        transform: translateZ(100%);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
`