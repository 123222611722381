import React from 'react';
// Styled components
import { HustleWrap, HustleTitle, HustleText, GridHeaderWrap, HustlePhotoGrid, ImageResponsive } from './style.js';
// Images
import Hustle_av191 from './assets/Hustle_av191.jpg';
import Hustle_av192 from './assets/Hustle_av192.jpg'
import Hustle_av193 from './assets/Hustle_av193.jpg'
import Hustle_av194 from './assets/Hustle_av194.jpg'
import Hustle_av195 from './assets/Hustle_av195.jpg'
import Hustle_av196 from './assets/Hustle_av196.jpg'
import Hustle_av197 from './assets/Hustle_av197.jpg'
import Hustle_av198 from './assets/Hustle_av198.jpg'
import Hustle_av199 from './assets/Hustle_av199.jpg'

import Hustle_av181 from './assets/Hustle_av181.jpg';
import Hustle_av182 from './assets/Hustle_av182.jpg'
import Hustle_av183 from './assets/Hustle_av183.jpg'
import Hustle_av184 from './assets/Hustle_av184.jpg'
import Hustle_av185 from './assets/Hustle_av185.jpg'
import Hustle_av186 from './assets/Hustle_av186.jpg'
import Hustle_av187 from './assets/Hustle_av187.jpg'
import Hustle_av188 from './assets/Hustle_av188.jpg'
import Hustle_av189 from './assets/Hustle_av189.jpg'

import Hustle_av171 from './assets/Hustle_av171.jpg';
import Hustle_av172 from './assets/Hustle_av172.jpg'
import Hustle_av173 from './assets/Hustle_av173.jpg'
import Hustle_av174 from './assets/Hustle_av174.jpg'
import Hustle_av175 from './assets/Hustle_av175.jpg'
import Hustle_av176 from './assets/Hustle_av176.jpg'
import Hustle_av177 from './assets/Hustle_av177.jpg'
import Hustle_av178 from './assets/Hustle_av178.jpg'
import Hustle_av179 from './assets/Hustle_av179.jpg'

import Hustle_rup1 from './assets/Hustle_rup1.jpg';
import Hustle_rup2 from './assets/Hustle_rup2.jpg'
import Hustle_rup3 from './assets/Hustle_rup3.jpg'
import Hustle_rup4 from './assets/Hustle_rup4.jpg'
import Hustle_rup5 from './assets/Hustle_rup5.jpg'
import Hustle_rup6 from './assets/Hustle_rup6.jpg'
import Hustle_rup7 from './assets/Hustle_rup7.jpg'
import Hustle_rup8 from './assets/Hustle_rup8.jpg'
import Hustle_rup9 from './assets/Hustle_rup9.jpg'

class Hustle extends React.Component {

  render() {
    // console.log(ImageImport)
    return (
      <div>
        <HustleWrap>
          <HustleTitle>HUSTLE</HustleTitle>
          <HustleText>Adrienne Fox curates personalized experiences and captures portraiture representing the unique vibe of your event or social occasion. Shooting a mix of instant film and digital, Fox will build a diverse collection of beautiful and smart imagery. An initial collaborative client session will ensure the creation of a vision uniquely yours,  representing your event through people, fashion, and culture.</HustleText>
        </HustleWrap>
        <GridHeaderWrap>
          <p><span className="bebasKai">Client</span>&ensp;Walker Art Center,&ensp;Avant Garden 2019</p>
        </GridHeaderWrap>
        <HustlePhotoGrid>
            <ImageResponsive src={Hustle_av191} />
            <ImageResponsive src={Hustle_av192} />
            <ImageResponsive src={Hustle_av193} />
            <ImageResponsive src={Hustle_av194} />
            <ImageResponsive src={Hustle_av195} />
            <ImageResponsive src={Hustle_av196} />
            <ImageResponsive src={Hustle_av197} />
            <ImageResponsive src={Hustle_av198} />
            <ImageResponsive src={Hustle_av199} />
        </HustlePhotoGrid>
        <GridHeaderWrap>
          <p><span className="bebasKai">Client</span>&ensp;Walker Art Center,&ensp;Avant Garden 2018</p>
        </GridHeaderWrap>
        <HustlePhotoGrid>
            <ImageResponsive src={Hustle_av181} />
            <ImageResponsive src={Hustle_av182} />
            <ImageResponsive src={Hustle_av183} />
            <ImageResponsive src={Hustle_av184} />
            <ImageResponsive src={Hustle_av185} />
            <ImageResponsive src={Hustle_av186} />
            <ImageResponsive src={Hustle_av187} />
            <ImageResponsive src={Hustle_av188} />
            <ImageResponsive src={Hustle_av189} />
        </HustlePhotoGrid>
        <GridHeaderWrap>
          <p><span className="bebasKai">Client</span>&ensp;Walker Art Center,&ensp;Allen Ruppersberg Opening 2018</p>
        </GridHeaderWrap>
        <HustlePhotoGrid>
            <ImageResponsive src={Hustle_rup1} />
            <ImageResponsive src={Hustle_rup2} />
            <ImageResponsive src={Hustle_rup3} />
            <ImageResponsive src={Hustle_rup4} />
            <ImageResponsive src={Hustle_rup5} />
            <ImageResponsive src={Hustle_rup6} />
            <ImageResponsive src={Hustle_rup7} />
            <ImageResponsive src={Hustle_rup8} />
            <ImageResponsive src={Hustle_rup9} />
        </HustlePhotoGrid>
        <GridHeaderWrap>
          <p><span className="bebasKai">Client</span>&ensp;Walker Art Center,&ensp;Avant Garden 2017</p>
        </GridHeaderWrap>
        <HustlePhotoGrid>
            <ImageResponsive src={Hustle_av171} />
            <ImageResponsive src={Hustle_av172} />
            <ImageResponsive src={Hustle_av173} />
            <ImageResponsive src={Hustle_av174} />
            <ImageResponsive src={Hustle_av175} />
            <ImageResponsive src={Hustle_av176} />
            <ImageResponsive src={Hustle_av177} />
            <ImageResponsive src={Hustle_av178} />
            <ImageResponsive src={Hustle_av179} />
        </HustlePhotoGrid>
      </div>
    );
  }
}

export default Hustle