import React from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
// Styled components
import { HomeWrap, HomeImg } from './style.js';
import HomeImage1 from './assets/home_01.jpg'
import HomeImage2 from './assets/home_02.jpg'
import HomeImage3 from './assets/home_03.jpg'
import HomeImage4 from './assets/home_04.jpg'
import HomeImage5 from './assets/home_05.jpg'
import HomeImage6 from './assets/home_06.jpg'

class HomeView extends React.Component {

  state = {
    currentImage: this.getRandomImageId(),
  };

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ currentImage: this.getNextImageId() }), 15000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getRandomImageId() {
    const min = 0;
    const max = 6;
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getNextImageId() {
    const curId = this.state.currentImage;
    let nextId;
    if (curId === 6) { nextId = 0 } else { nextId = curId + 1 }
    return nextId;
  }

  render() {

    const images = [
      HomeImage1,
      HomeImage2,
      HomeImage3,
      HomeImage4,
      HomeImage5,
      HomeImage6,
    ];

    let currentImage = images[this.state.currentImage];

    // console.log('Home Props:', this.props, 'Home State:', this.state);

    return (
      <div>

        <HomeWrap>
          <HomeImg src={currentImage}  />
        </HomeWrap>

        <Router><Route></Route></Router>
      </div>
    );
  }
}

export default HomeView