import React from 'react';
// React Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Components
import ImageScroll from 'components/ImageScroll/ImageScroll.js';
// import ImageCarousel from 'components/ImageCarousel/ImageCarousel.js';
import ImageSlider from 'components/ImageSlider/ImageSlider.js';

// ModalSwitch: React Router
// https://reacttraining.com/react-router/web/example/modal-gallery

class ModalSwitch extends React.Component {


  previousLocation = this.props.location;

  state = {
    imageIndex: null,
  }

  

  UNSAFE_componentWillUpdate(nextProps) {
    // console.log('Modal Switch Props:', this.props);
    const location = this.props.location;
    // set previousLocation if props.location is not modal
    if (nextProps.history.action !== "POP" && (!location.state || !location.state.modal)) {
      this.previousLocation = this.props.location;
    }
  }

  getIndexFromScrollClick = indexFromScrollClick => {
    this.setState({ imageIndex: indexFromScrollClick });
  }

  render() {

    const location = this.props.location;

    const isModal = !!(location.state && location.state.modal && this.previousLocation !== location); // not initial render
    // console.log('Modal Switch Current Location: ', location, 'Previous Location: ', this.previousLocation, 'Is Modal: ', isModal);
    const currentProject = location.pathname.split('projects/').pop().split('/').shift();
    return (<div key={location.key}>
      <Switch location={isModal
        ? this.previousLocation
        : location}>
        <Route path={`/projects/${currentProject}`} render={(props) => (<ImageScroll  gettingIndex={this.getIndexFromScrollClick} {...this.props} {...props} />)} />
        {/* <Route exact path={`/projects/${currentProject}/${this.props.imageImport[i].id}`} render={(props) => (<ImageCarousel {...props} />)} /> */}
        <Route component={ImageScroll} />
      </Switch>
      {
        isModal
          ? <Route path={`/projects/${currentProject}/:id`} render={(props) => (<ImageSlider imageIndex={this.state.imageIndex } {...this.props} {...props} />)} />
          : null
      }
      <Router></Router>
    </div>)
  }
} // modal switch end

export default ModalSwitch;
