import styled, { css } from 'styled-components';
import { Close, NavigateBefore, NavigateNext } from 'styled-icons/material'
import { fadeIn } from '../tools/KeyFrames.js';
import { isMobileOrTouch } from '../tools/DeviceCheck.js';

export const ModalSwipe = styled.section`
z-index: 9999;
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0
background-color: ${isMobileOrTouch() ? "rgb(15,15,15)" : "rgb(255,255,255)"};
`;

export const ImageResponsiveMixin = css`
height: auto;
width: auto;
max-height: 100vh;
max-width: 100vw;
padding: 2vw;
position: relative;
margin: ${isMobileOrTouch() ? "50vh 0 0 0" : "50vh 0 0 50vw;"};
transform: ${isMobileOrTouch() ? "translate(0%,-50%)" : "translate(-50%,-50%)"};
@media only screen and (min-width: 600px) and (max-width: 1200px){
  max-height: 94vh;
  left: ${isMobileOrTouch() ? "50%" : "0"};
  margin: ${isMobileOrTouch() ? "3vh 0 0 0" : "50vh 0 0 50vw;"};
  transform: ${isMobileOrTouch() ? "translateX(-50%)" : "translate(-50%,-50%)"};
 };
 @media (min-width: 480px) {
  animation-name:${fadeIn}; animation-duration: 2s; animation-timing-function: ease; animation-delay: 0s; animation-iteration-count: 1; animation-direction: normal; animation-fill-mode: forwards; animation-play-state: running;
} 
  
`;
// ${isMobileOrTouch() ? "" : "animation-name:${fadeIn}; animation-duration: 2s; animation-timing-function: ease; animation-delay: 0s; animation-iteration-count: 1; animation-direction: normal; animation-fill-mode: forwards; animation-play-state: running;"}    

export const ImageResponsive = styled.img`${ImageResponsiveMixin}`;

export const NavBeforeIcon = styled(NavigateBefore)`
height: 2rem;
width: 2rem;
padding: .5rem
position: absolute;
top: 45vh;
z-index: 1;
left: 0;
&:hover {
  background-color: rgba(200,200,200,1);
  color: rgba(30,30,30,1);
}
@media only screen and (min-width: 0px) and (max-width: 812px) {
  display: none
 }
`;
export const NavNextIcon = styled(NavigateNext)`
height: 2rem;
width: 2rem;
padding: .5rem;
position: absolute;
top: 45vh;
z-index: 1;
right: 0;
&:hover {
  background-color: rgba(200,200,200,1);
  color: rgba(30,30,30,1);
}
@media only screen and (min-width: 0px) and (max-width: 812px) {
  display: none
 }
`;

export const CloseIcon = styled(Close)`
height: 2rem;
width: 2rem;
padding: .5rem;
position: absolute;
top: 2vh;
right: 2vw;
background-color: ${isMobileOrTouch() ? "rgb(40,40,40)" : "rgb(255,255,255)"};
color: ${isMobileOrTouch() ? "rgb(150,150,150)" : "rgb(0,0,0)"};
&:hover {
  background-color: rgb(200,200,200);
  color: rgb(30,30,30);
}
@media only screen and (max-width: 600px){
  height: 2rem;
  width: 2rem;
  padding: .25rem
 }
`;