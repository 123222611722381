import styled from 'styled-components';
import { Instagram } from 'styled-icons/fa-brands/Instagram'

export const ContactWrap = styled.section`
    display: grid;
    grid-gap: 0 1rem;
    grid-template-columns: auto;
    justify-content: center;
    padding: 6vh 6vw 0;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto;
    @media (min-width: 880px) {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 2.3rem auto 2.3rem auto;
        padding: 6vh 6vw 0 0;
    } 
`;
export const ContactHead = styled.h1`
    font-size: 2rem;
    text-align: left;
    font-weight: 400;
    font-style: normal;
    font-family: bebas-kai,sans-serif;
    text-align: center;
    line-height: 1;
    grid-column: 1;
    @media (min-width: 880px) {
        grid-column: 2;
        padding: 0 0 0 2rem;
        text-align: left;
    }
`;
export const ContactText = styled.p`
    font-size: 1rem;
    text-align: center;
    max-width: 800px;
    margin: 0 0 2rem 0;
    @media (min-width: 880px) {
        padding: 0 0 0 2rem;
        grid-column: 2;
        text-align: left;
    }
`;
export const ResponsiveImage = styled.img`
    width: 100%;
    height: auto;
    min-height: 200px;
    min-width: 200px;
    max-height: 1500px;
    max-width: 1500px;
    margin: 0 auto;
    left: 0;
    right: 0;
    grid-row: 5;
    @media (min-width: 880px) {
        height: auto;
        grid-row-start: 1;
        grid-row-end: 6;
    } 
`;
export const EmailLink = styled.span`
    &:hover {
        color: rgba(183,107,121,1);
    }
`;
export const InstaLink = styled.span`
    &:hover {
        background: -webkit-linear-gradient(rgb(255,215,0),rgb(208,0,180));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;
export const InstaIcon = styled(Instagram)`
    display: inline-block;
    vertical-align: -0.3em;
    height: auto;
    width: 1rem;
    cursor: pointer;
    padding: 1px 3px;
    ${InstaLink}:hover & {
      background: -webkit-gradient(linear,left bottom,right top,from(rgb(255,215,0)),to(rgb(208,0,180)));
      color: white;
    }
`;
// export const FormWrap = styled.section`
//     display: grid;
//     grid-gap: 1rem;
//     grid-template-columns: auto;
//     justify-content: center;
//     padding: 6vh 6vw 0;
// `;
// export const FormText = styled.p`
//     font-szie: .8rem;
//     text-align: center;
//     max-width: 800px;
// `;
// export const FormHead = styled.h1`
//     font-size: 2.5rem;
//     text-align: center;
//     font-weight: 400;
//     font-style: normal;
//     font-family: bebas-kai,sans-serif;
//     margin: 2.5rem 0 .5rem 0;
// `;
// export const FormSubHead = styled.h2`
//     font-size: 2rem;
//     text-align: center;
//     font-weight: 400;
//     font-style: normal;
//     font-family: bebas-kai,sans-serif;
//     margin: 2rem 0;
// `;

// export const Form = styled.form`
//     // background-color: rgb(230,230,230);
// `;

// export const Input = styled.input`
//   padding: 0.5em;
//   margin: 0.5em;
//   color: rgb(183,107,121);
//   color: rgb(121,77,83);
//   color: rgb(89,66,66); 
//   background: rgb(230,230,230);
//   border: none;
//   border-radius: 3px;
// `;
// export const TextArea = styled.textarea`
//   padding: 0.5em;
//   margin: 0.5em;
//   color: rgb(183,107,121);
//   color: rgb(121,77,83);
//   color: rgb(89,66,66); 
//   background: rgb(230,230,230);
//   border: none;
//   border-radius: 3px;
// `;

// export const ResponsiveImage = styled.img`
//     width: 100%;
//     height: auto;
//     max-height: 800px;
//     max-width: 800px;
//     margin: 0 auto;
//     left: 0;
//     right: 0;
//     @media (min-width: 480px) {
//         height: auto;
//     } 
// `;