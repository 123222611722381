// Styled components
import styled from 'styled-components';
import { Instagram } from 'styled-icons/fa-brands/Instagram'
import { Envelope } from 'styled-icons/fa-regular/Envelope'

export const FooterInfo = styled.footer`
    width: 100vw;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto auto auto;
    justify-content: center;
    margin-top: 1rem;
    padding: 1rem 0;

    @media (min-width: 480px) {
    }
`;
export const FooterAFLogo = styled.img`
    height: auto;
    width: 1.2rem;
    margin: .2rem;
    cursor: pointer;
    @media (min-width: 480px) {
        &:hover {
            background-color: rgba(220,220,220,.5);
    }
}    
`;

export const InstaIcon = styled(Instagram)`
    height: auto;
    width: 1.1rem;
    margin: .2rem;
    padding: 1px 3px;
    cursor: pointer;
    &:hover {
      background: -webkit-gradient(linear,left bottom,right top,from(rgb(255,215,0)),to(rgb(208,0,180)));
      color: white;
    }
`;
export const EnvIcon = styled(Envelope)`
    height: auto;
    width: 1.2rem;
    margin: .2rem;
    cursor: pointer;
    @media (min-width: 480px) {
        &:hover {
            background-color: rgba(220,220,220,.5);
            color: rgba(183,107,121,1);
        }
    } 
`;
