import styled, { css } from 'styled-components';
import { fadeIn } from '../tools/KeyFrames.js';
// import { isMobileOrTouch }from '../tools/DeviceCheck.js';

export const ImageScrollWrap = styled.section`
    display: grid;
    grid-gap: .1em;
    grid-template-columns: auto;
    justify-content: center;
    padding: 1vw;
`;

const ImageResponsiveMixin = css`
    display: block;
    height: auto;
    width: 100%;
    padding: 0;
    margin: 0;
    max-height: 800px;
    max-width: 800px;
    @media (min-width: 480px) {
        animation-name:${fadeIn}; animation-duration: 2s; animation-timing-function: ease; animation-delay: 0s; animation-iteration-count: 1; animation-direction: normal; animation-fill-mode: forwards; animation-play-state: running;
    } 
    @media (min-width: 1500px) {
        max-height: 1000px;
        max-width: 1000px;
    } 
    object-fit: contain;
`;

// ${isMobileOrTouch() ? "" : "animation-name:${fadeIn}; animation-duration: 2s; animation-timing-function: ease; animation-delay: 0s; animation-iteration-count: 1; animation-direction: normal; animation-fill-mode: forwards; animation-play-state: running;"}    

export const ImageResponsive = styled.img`${ImageResponsiveMixin}`;