import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// Styled components
import { FooterInfo, InstaIcon, EnvIcon, FooterAFLogo } from './style.js';
// import { ImageImport } from '../tools/ImageImport.js';
import afLogo from '../../logos/AF-Logo1.png'

class Footer extends React.Component {

    render() {

        return (
            <div>
                <FooterInfo>
                    <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/adrienneleighfox"><InstaIcon/></a>  
                    <Link to={`/`}><FooterAFLogo src={afLogo} /></Link>
                    <Link to={`/Contact`}><EnvIcon /></Link>
                </FooterInfo>
            </div>
        );
    }
}

export default Footer;
