import React from 'react';
// React Router
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// Styled components
import { ImageScrollWrap, ImageResponsive } from './style.js';
// import ExifReader from 'exifreader';

class ImageScroll extends React.Component {

  getCurrentProject() {
    // Finding current project name based on URL. Importing image folder 
    // Filtering images by matching project name property with currentProject.
    const currentLocation = this.props.match.url;
    const currentProject = currentLocation.replace(/.*\/projects\//, "").replace(/\/.*/, "");
    const projArray = this.props.imageImport.filter((image) => { return image.project === currentProject });
    return projArray;
  }

  handleClickImage = (event) => {
    let imageIndex = event.target.getAttribute('value');
    // console.log(`Click Image Link: ${imageIndex}`);
    this.props.gettingIndex(imageIndex);
  }

  // handleClick = () => {
  //   let fileBuffer
  //   let el = document.getElementById('the-img');
  //   let imageUrl = el.src;
  //   console.log('imageUrl: ', imageUrl);
  //   fetch(imageUrl)
  //   .then(function(response) {
  //     return response.blob()
  //   })
  //   .then(function(blob) {
  //     console.log('blob: ', blob);

  //           var arrayPromise = new Promise(function(resolve) {
  //               var reader = new FileReader();

  //               reader.onloadend = function() {
  //                   resolve(reader.result);
  //               };

  //               reader.readAsArrayBuffer(blob);
  //           });

  //           arrayPromise.then(function(array) {
  //               console.log("Array contains", array.byteLength, "bytes.");

  //               let fileBuffer = array;

  //               console.log('FileBuffer: ', fileBuffer);
  //               try {
  //                 var tags = ExifReader.load(fileBuffer);
          
  //                 // The MakerNote tag can be really large. Remove it to lower
  //                 // memory usage if you're parsing a lot of files and saving the
  //                 // tags.
  //                 delete tags['MakerNote'];
          
  //                 listTags(tags);
  //               } catch (error) {
  //                   alert(error);
  //               }


  //           });

      
  //   });

    
  //   function listTags(tags) {
  //       var name;
  //       for (name in tags) {
  //           console.log('tag: ', tags[name].description)
  //       }
  //   }

  // } // END handleclick

  

  

  render() {
    // console.log('Image Scroll Props:', this.props);
    let projectArray = this.getCurrentProject();
    // console.log('Current Project Images:', projectArray);
    
    return (<ImageScrollWrap style={{
      gridTemplateColumns: `${ projectArray[0].projectLayout === "column" ? "auto" : "auto auto auto auto"}`,
    }}>
    {/* <ImageResponsive id="the-img" src="http://localhost:5000/static/media/fam_33.f44ce9c4.jpg" /> */}
      {
        projectArray.map(i => {
          return (<Link onClick={this.handleClickImage} key={i.key} to={{
            pathname: `/projects/${i.project}/${i.id}`,
            state: {
              modal: true
            }
          }}>
            <ImageResponsive src={i.src} value={i.projectOrder} alt={i.title} style={{
              margin: `${projectArray[0].projectLayout === "column" ? "1vh 0" : "0"}`,
            }} />
          </Link>)
        })
      }
      {/* <button onClick={this.handleClick}>Hit it</button> */}
      <Router><Route /></Router>
    </ImageScrollWrap>)

  } // End render()

}; // End ImageScroll

export default ImageScroll

