import React from 'react';
// Styled components
// import { FormText, FormWrap, FormHead, Form, Input, TextArea } from './style.js';
import { ContactWrap, ContactHead, ContactText, ResponsiveImage, EmailLink, InstaLink, InstaIcon } from './style.js';
import ContactPortrait from './assets/contact_01.jpg'


class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {name: '', email: '', message: ''};
    this.formRef = React.createRef();

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleSubmit(event) {
    this.formRef.current.reportValidity();
    event.preventDefault();
    // console.log("State: ", this.state, "Form:", this.formRef.current, " Validate: ", this.formRef.current.reportValidity())
  }

  
  handleNameChange(e) {
    e.target.setCustomValidity("")
    this.setState({name: e.target.value});
    // console.log("nameChange", e.target)
  }
  invalidName(e){
    // console.log('Invalid: ', e.target)
    e.target.setCustomValidity("Please Enter Name")
  }
  
  
handleEmailChange(e) {
    e.target.setCustomValidity("")
    this.setState({email: e.target.value});
    // console.log("emailChange", e.target)
 }
 invalidEmail(e){
  // console.log('Invalid: ', e.target)
  e.target.setCustomValidity("Please Enter Email")
}

handleMessageChange(e) {
  e.target.setCustomValidity("")
  this.setState({message: e.target.value});
  // console.log("messageChange", e.target)
}
  invalidMessage(e){
    // console.log('Invalid: ', e.target)
    e.target.setCustomValidity("Please Enter Message")
  }

  render() {
    // console.log('Contact Props:', this.props)
    return (

      <ContactWrap>
        <ContactHead>Contact</ContactHead>
        <ContactText>For bookings or prints, contact <EmailLink><a href="mailto:adriennefox.photo@gmail.com">adriennefox.photo@gmail.com</a></EmailLink></ContactText>
        <ContactHead>Instagram</ContactHead>
        <ContactText><InstaLink><InstaIcon/><a target="_blank" rel="noopener noreferrer" href="https://instagram.com/adrienneleighfox"> @adrienneleighfox</a></InstaLink></ContactText>
        <ResponsiveImage src={ContactPortrait} />
      </ContactWrap>

      // <FormWrap>
      //   <div>
      //   <FormHead>Contact</FormHead>
      //   <ResponsiveImage src={ContactPortrait} />
      //   <FormText>For bookings or prints, contact <a href="mailto:adriennefox.photo@gmail.com">adriennefox.photo@gmail.com</a></FormText>
      //   <iframe src="https://goo.gl/forms/zvfG0pNVzQqeWd0C2" width="640" height="930" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
      //     <Form ref={this.formRef} onSubmit={this.handleSubmit} method="POST" action="https://formspree.io/scottfox5@gmail.com">
      //     <div class="hpot">
      //       <label>Leave this field empty: <input name="_gotcha"/></label>
      //     </div>
      //     <Input 
      //       type="text" 
      //       id="username" 
      //       required 
      //       placeholder="Enter Name" 
      //       onInvalid={this.invalidName}
      //       onChange={this.handleNameChange} 
      //       value={this.state.name}
      //     />
      //     <Input 
      //       type="email" 
      //       id="email" 
      //       required 
      //       placeholder="Enter Email" 
      //       onInvalid={this.invalidEmail}
      //       onChange={this.handleEmailChange}
      //       value={this.state.email}
      //     />
      //     <TextArea
      //       type="text" 
      //       id="message" 
      //       required 
      //       placeholder="Enter Message" 
      //       onInvalid={this.invalidMessage}
      //       onChange={this.handleMessageChange}
      //       value={this.state.message}
      //     />
      //     <Input 
      //       type="submit" 
      //       id="submit" 
      //       value="Submit"
      //       placeholder="Submit" 
      //     />
      //     </Form>
      //   </div>
      // </FormWrap>

    );
  }
}

export default Contact