import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// Components
import { ProjectData } from '../tools/ProjectData.js';
// import { ImageImport } from '../tools/ImageImport.js';

class ProjectsList extends React.Component {

  render() {

    return (
      <ul>
        {ProjectData.map(i => {
          return (<li key={i.id }><Link to={`/projects/${i.url}`}>{i.title}</Link></li>)
          })}
        <Router><Route></Route></Router>
      </ul>
    );
  }
}

export default ProjectsList;
