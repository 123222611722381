import React from 'react';
// Swipeable Views
import SwipeableViews from 'react-swipeable-views';
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils';
import { mod } from 'react-swipeable-views-core';
// Styled Components, Styled Icons 
import { ModalSwipe, ImageResponsive, NavBeforeIcon, NavNextIcon, CloseIcon } from './style.js';

const VirtualizeSwipeableViews = virtualize(bindKeyboard(SwipeableViews));

class SwipeViewer extends React.Component {

  state = {
    index: 0,
  };

  componentDidMount() {
    this.setState({ index: parseInt(this.props.imageIndex, 10) })
    // window.history.replaceState('', 'Title', 'gallery');
  }

  handleChangeIndex = index => {
    this.setState({ index });
  }

  getCurrentProject() {
    // Finding current project name based on URL. Importing image folder 
    // Filtering images by matching project name property with currentProject.
    const currentProject = this.props.location.pathname.replace(/.*\/projects\//, "").replace(/\/.*/, "");
    let projArray = this.props.imageImport.filter((image) => { return image.project === currentProject });
    return projArray;
  }

  handleClickPrev = () => {
    let projectArray = this.getCurrentProject();
    let newIndex;
    // Decrement index. If less than first item, go to last item
    this.state.index < 1 ? newIndex = projectArray.length - 1 : newIndex = this.state.index - 1;
    this.setState({ index: newIndex });
  }

  handleClickNext = () => {
    let projectArray = this.getCurrentProject();
    let newIndex;
    // Increment index. If greater than last item, go to first item
    this.state.index >= projectArray.length - 1 ? newIndex = 0 : newIndex = this.state.index + 1;
    this.setState({ index: newIndex });
  }

  hideVerticalScroll = () => {
    return document.getElementById('body').style.overflow = "hidden";
  }

  handleModalClose = (e) => {
    const history  = this.props.history;
    e.stopPropagation();
    history.goBack();
    return document.getElementById('body').style.overflow = "visible";
  }

  slideRenderer = (params) => {
    let projectArray = this.getCurrentProject();
    const { index, key } = params;
    // console.log('Slide Renderer params: ', params, 'State: ', this.state, 'mod(): ', mod(index, projectArray[0].projectCount ))
    for (let i = 0; i < projectArray[0].projectCount; i++) {
      if (mod(index, projectArray[0].projectCount) === i) {
        return (
          <div key={key} onClick={this.handleClickNext} style={{ backgroundColor: this.props.deviceCheck ? "rgb(15,15,15)" : "rgb(255,255,255)", }}>
            <ImageResponsive src={projectArray[i].src} alt={projectArray[i].title} />
          </div>
        );
      }
    }
  }// End slideRenderer

  render() {

    this.hideVerticalScroll();

    // defaault transition effect
    // duration: "0.3s", easeFunction:"cubic-bezier(0.15, 0.3, 0.25, 1)", delay: "0s" 
      return (
        <ModalSwipe>
          {this.props.deviceCheck ? 
          <VirtualizeSwipeableViews springConfig={{ duration: "0.4s", easeFunction: "cubic-bezier(0.250, 0.100, 0.250, 1.000)", delay: "0s" }} ignoreNativeScroll index={this.state.index} onChangeIndex={this.handleChangeIndex} slideRenderer={this.slideRenderer} />
          : 
          <VirtualizeSwipeableViews animateTransitions={false} overscanSlideAfter={0} enableMouseEvents index={this.state.index} onChangeIndex={this.handleChangeIndex} slideRenderer={this.slideRenderer} />
          }
          <NavBeforeIcon onClick={this.handleClickPrev} />
          <NavNextIcon onClick={this.handleClickNext} />
          <CloseIcon onClick={this.handleModalClose} />
        </ModalSwipe>)
  } // End render
} // End SwipeViewer

export default SwipeViewer
